import { NgModule } from '@angular/core';

import { TaFormlyDateModule } from './ta-date/ta-date.module';
import { TaFormlyDateTimePickerModule } from './ta-datetimepicker/ta-datetimepicker.module';
import { TaFormlyInputModule } from './ta-input/ta-input.module';
import { TaFormlyToggleModule } from './ta-toggle/ta-toggle.module';
import { TaFormlyAssetSelectModule } from './ta-asset-select/ta-asset-select.module';
import { TaFormlyProjectSelectModule } from './ta-project-select/ta-project-select.module';
import { TaFormlyUserSelectModule } from './ta-user-select/ta-user-select.module';

@NgModule({
    imports: [
        TaFormlyDateModule,
        TaFormlyDateTimePickerModule,
        TaFormlyInputModule,
        TaFormlyToggleModule,
        TaFormlyAssetSelectModule,
        TaFormlyProjectSelectModule,
        TaFormlyUserSelectModule
    ],
    exports: [],
})
export class TaFormlyModule { }
