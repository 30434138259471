import { NgModule } from '@angular/core';

import { TaFormlyDateModule } from './ta-date/ta-date.module';
import { TaFormlyDateTimePickerModule } from './ta-datetimepicker/ta-datetimepicker.module';
import { TaFormlyInputModule } from './ta-input/ta-input.module';
import { TaFormlyToggleModule } from './ta-toggle/ta-toggle.module';

@NgModule({
    imports: [
        TaFormlyDateModule,
        TaFormlyDateTimePickerModule,
        TaFormlyInputModule,
        TaFormlyToggleModule
    ],
    exports: [],
})
export class TaFormlyModule { }
