import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';

import { map } from 'rxjs';

import { ClientSelectionState } from '@state-management/states';

import { ConfigService, StorageService, UserService } from '@services';

import { ResetAssetTemplatesState, ResetAuditTemplatesState, ResetClientSelectionState, ResetClientsState, ResetReportsState, ResetUsersState } from '@state-management/actions';

import { Asset, AssetSection, Audit, AuditImage, Client, Contact, GeneratedReport, Image, Report, Role, Signature, StandardReport, Template, TickAuditConfig, TickAuditTerms, User } from '@models';

import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private router: Router,
        private storageService: StorageService,
        private store: Store,
        private userService: UserService
    ) { }

    // ASSETS

    public getAssetsByAudit(auditId: string) {
        const url = this.configService.restApiUrl + '/admin/assets/by_audit/' + auditId;
        return this.makeRequest<Record<string, Asset>>('get', url);
    }

    // AUDITS

    public getAudit(auditId: string) {
        const url = this.configService.restApiUrl + '/admin/audits/audit/' + auditId;
        return this.makeRequest<Audit>('get', url);
    }

    public queryAudits(clientId?: string, status?: string, templateId?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/audits/query';
        const params: string[] = [];
        if (clientId) {
            params.push('client_id=' + clientId);
        } else if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (status) {
            params.push('status=' + status);
        }
        if (templateId) {
            params.push('template_id=' + templateId);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }

        return this.makeRequest<(Audit & { name: string })[]>('get', url);
    }

    // ASSET TEMPLATES

    public downloadAssetTemplate(templateId: string) {
        const url = this.configService.restApiUrl + '/admin/asset_templates/download/' + templateId;
        return this.makeRequest<{ filename: string, template: Template }>('get', url);
    }

    public getAssetTemplate(templateId: string) {
        const url = this.configService.restApiUrl + '/admin/asset_templates/template/' + templateId;
        return this.makeRequest<(Template & { contact: Contact })>('get', url);
    }

    public queryAssetTemplates(assetType: string, clientId?: string, status?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/asset_templates/query';
        const params: string[] = [];
        if (clientId) {
            params.push('client_id=' + clientId);
        } else if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (assetType) {
            params.push('asset_type=' + assetType);
        }
        if (status) {
            params.push('status=' + status);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }

        return this.makeRequest<(Template & { client_name: string })[]>('get', url);
    }

    public uploadAssetTemplate(clientId: string, template: Template) {
        const url = this.configService.restApiUrl + '/admin/asset_templates/upload';
        const body = {
            client_id: clientId,
            asset_template: template
        };
        return this.makeRequest<string>('post', url, body);
    }

    // AUDIT TEMPLATES

    public downloadAuditTemplate(templateId: string) {
        const url = this.configService.restApiUrl + '/admin/audit_templates/download/' + templateId;
        return this.makeRequest<{ filename: string, template: Template }>('get', url);
    }

    public getAuditTemplate(templateId: string) {
        const url = this.configService.restApiUrl + '/admin/audit_templates/template/' + templateId;
        return this.makeRequest<(Template & { contact: Contact })>('get', url);
    }

    public queryAuditTemplates(clientId?: string, status?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/audit_templates/query';
        const params: string[] = [];
        if (clientId) {
            params.push('client_id=' + clientId);
        } else if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (status) {
            params.push('status=' + status);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }

        return this.makeRequest<(Template & { client_name: string })[]>('get', url);
    }

    public uploadAuditTemplate(clientId: string, template: Template) {
        const url = this.configService.restApiUrl + '/admin/audit_templates/upload';
        const body = {
            client_id: clientId,
            template
        };
        return this.makeRequest<string>('post', url, body);
    }

    // CLIENTS

    public archiveClient(clientId: string) {
        const url = this.configService.restApiUrl + '/admin/clients/archive/' + clientId;
        return this.makeRequest<string>('put', url);
    }

    public createClient(clientModel: Client) {
        const url = this.configService.restApiUrl + '/admin/clients/create';
        const body = clientModel;
        return this.makeRequest<{ message: string, id: string }>('post', url, body);
    }

    public createOVHContainer(clientId: string) {
        const url = this.configService.restApiUrl + '/admin/clients/storage/' + clientId;
        return this.makeRequest<string>('post', url);
    }

    public deleteClient(clientId: string) {
        const url = this.configService.restApiUrl + '/admin/clients/' + clientId;
        return this.makeRequest<string>('delete', url);
    }

    public deleteOVHContainer(clientId: string) {
        const url = this.configService.restApiUrl + '/admin/clients/storage/' + clientId;
        return this.makeRequest<string>('delete', url);
    }

    public getClient(clientId: string) {
        const url = this.configService.restApiUrl + '/admin/clients/client/' + clientId;
        return this.makeRequest<Client>('get', url);
    }

    public getStorage(clientId: string) {
        const url = this.configService.restApiUrl + '/admin/clients/storage/' + clientId;
        return this.makeRequest<{ audit_images: { data: AuditImage[], total_image_bytes: number, total_thumbnail_bytes: number, total_bytes: number }, reports: { data: GeneratedReport[], total_bytes: number }, total: number }>('get', url);
    }

    public getStorageObject(storagePath: string) {
        const url = this.configService.restApiUrl + '/admin/clients/storage/object';
        const body = { storage_path: storagePath };
        return this.makeRequest<string>('post', url, body);
    }

    public listClients() {
        const url = this.configService.restApiUrl + '/admin/clients/list';
        return this.makeRequest<Client[]>('get', url);
    }

    public queryClients(clientId?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/clients/query';
        const params: string[] = [];
        if (clientId) {
            params.push('client_id=' + clientId);
        } else if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }

        return this.makeRequest<Client[]>('get', url);
    }

    public updateClient(clientId: string, clientModel: Client) {
        const url = this.configService.restApiUrl + '/admin/clients/update/' + clientId;
        const body = clientModel;
        return this.makeRequest<string>('put', url, body);
    }

    // DASHBOARD

    public getSummary() {
        let url = this.configService.restApiUrl + '/admin/dashboard/summary';
        const params: string[] = [];
        if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }
        return this.makeRequest<{ [key: string]: { data: { count: number, status: string }[], total: number } }>('get', url);
    }

    // IMAGES

    public getImage(imageId: string) {
        const url = this.configService.restApiUrl + '/admin/images/image/' + imageId;
        return this.makeRequest<Image>('get', url);
    }

    public getImageIdsByAudit(auditId: string) {
        const url = this.configService.restApiUrl + '/admin/images/ids_by_audit/' + auditId;
        return this.makeRequest<{ [key: string]: { id: string } }>('get', url);
    }

    // REPORTS

    public activateReport(reportId: string) {
        const url = this.configService.restApiUrl + '/admin/reports/activate/' + reportId;
        return this.makeRequest<string>('post', url);
    }

    public archiveReport(reportId: string) {
        const url = this.configService.restApiUrl + '/admin/reports/archive/' + reportId;
        return this.makeRequest<string>('put', url);
    }

    public createNewVersionOfReport(reportId: string) {
        const url = this.configService.restApiUrl + '/admin/reports/new_version/' + reportId;
        return this.makeRequest<string>('post', url);
    }

    public createReport(reportModel: Report) {
        const url = this.configService.restApiUrl + '/admin/reports/create';
        return this.makeRequest<string>('post', url, reportModel);
    }

    public createReportImage(reportId: string, base64: string, name: string) {
        const url = this.configService.restApiUrl + '/admin/reports/' + reportId + '/image';
        const body = {
            base64,
            name
        };
        return this.makeRequest<string>('post', url, body);
    }

    public deleteReport(id: string) {
        const url = this.configService.restApiUrl + '/admin/reports/' + id;
        return this.makeRequest<string>('delete', url);
    }

    public deleteReportImage(reportId: string, imageId: string) {
        const url = this.configService.restApiUrl + '/admin/reports/' + reportId + '/image';
        const params = {
            image_id: imageId
        };
        return this.makeRequest<string>('delete', url, null, null, params);
    }

    public generateBackendExcel(reportId: string, reportParams: Record<string, string>, codeModel: string) {
        const url = this.configService.restApiUrl + '/admin/reports/generate_backend_excel/' + reportId;
        const body = {
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, excel: any }>('post', url, body);
    }

    public generateExcel(reportId: string, auditId: string, codeModel: string, reportParams: Record<string, string>) {
        const url = this.configService.restApiUrl + '/admin/reports/generate_excel/' + reportId;
        const body = {
            audit_id: auditId,
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, excel: any }>('post', url, body);
    }

    public generateBackendPdf(reportId: string, reportParams: Record<string, string>, codeModel: string) {
        const url = this.configService.restApiUrl + '/admin/reports/generate_backend_pdf/' + reportId;
        const body = {
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, pdf: string }>('post', url, body);
    }

    public generatePdf(reportId: string, auditId: string, codeModel: string, reportParams: Record<string, string>) {
        const url = this.configService.restApiUrl + '/admin/reports/generate_pdf/' + reportId;
        const body = {
            audit_id: auditId,
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, pdf: string }>('post', url, body);
    }

    public getReport(reportId: string) {
        const url = this.configService.restApiUrl + '/admin/reports/report/' + reportId;
        return this.makeRequest('get', url);
    }

    public getReportImages(reportId: string) {
        const url = this.configService.restApiUrl + '/admin/reports/' + reportId + '/images';
        return this.makeRequest<any[]>('get', url);
    }

    public queryReports(clientId?: string, status?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/reports/query';
        const params: string[] = [];
        if (clientId) {
            params.push('client_id=' + clientId);
        } else if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (status) {
            params.push('status=' + status);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }

        return this.makeRequest<(Report & { client_name: string })[]>('get', url);
    }

    public queryStandardReports(status?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/reports/standard/query';
        const params: string[] = [];
        if (status) {
            params.push('status=' + status);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }

        return this.makeRequest<Report[]>('get', url);
    }

    public updateReport(reportId: string, report: Report) {
        const url = this.configService.restApiUrl + '/admin/reports/' + reportId;
        const body = {
            report
        };
        return this.makeRequest<string>('put', url, body);
    }

    public updateStandardReport(reportId: string, report: StandardReport) {
        const url = this.configService.restApiUrl + '/admin/reports/standard/' + reportId;
        const body = {
            report
        };
        return this.makeRequest<string>('put', url, body);
    }

    // ROLES

    public queryRoles(clientId?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/roles/query';
        const params: string[] = [];
        if (clientId) {
            params.push('client_id=' + clientId);
        } else if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }
        console.log('url', url);
        return this.makeRequest<Role[]>('get', url);
    }

    // SETTINGS

    public createTermsAndConditions(termsAndConditions: string) {
        const url = this.configService.restApiUrl + '/admin/settings/terms-and-conditions';
        const body = {
            terms_and_conditions: termsAndConditions
        };
        return this.makeRequest<TickAuditTerms>('post', url, body);
    }

    public getConfig() {
        const url = this.configService.restApiUrl + '/admin/settings/config';
        return this.makeRequest<TickAuditConfig>('get', url);
    }

    public getTermsAndConditions() {
        const url = this.configService.restApiUrl + '/admin/settings/terms-and-conditions';
        return this.makeRequest<TickAuditTerms>('get', url);
    }

    public updateConfig(config: TickAuditConfig) {
        const url = this.configService.restApiUrl + '/admin/settings/config';
        const body = config;
        return this.makeRequest('put', url, body);
    }

    public updateTermsAndConditions(termsAndConditions: string) {
        const url = this.configService.restApiUrl + '/admin/settings/terms-and-conditions';
        const body = {
            terms_and_conditions: termsAndConditions
        };
        return this.makeRequest<TickAuditTerms>('put', url, body);
    }

    // USERS

    public changePassword(userId: string, password: string) {
        const url = this.configService.restApiUrl + '/admin/users/password';
        const body = {
            user_id: userId,
            password
        };
        return this.makeRequest<string>('post', url, body);
    }

    public createUser(userModel: any) {
        const url = this.configService.restApiUrl + '/admin/users/create';
        const body = userModel;
        return this.makeRequest<string>('post', url, body);
    }

    public getUser(userId: string) {
        const url = this.configService.restApiUrl + '/admin/users/user/' + userId;
        return this.makeRequest<{ user: (User & { sections: AssetSection[] }), role: Role, assignedTemplates: { title: string, description: string, version: number }[], signature: Signature }>('get', url);
    }

    public logoutUser(userId: string) {
        const url = this.configService.restApiUrl + '/admin/users/logout/' + userId;
        return this.makeRequest<string>('get', url);
    }

    public queryUsers(clientId?: string, status?: string, limit?: number, offset?: number) {
        let url = this.configService.restApiUrl + '/admin/users/query';
        const params: string[] = [];
        if (clientId) {
            params.push('client_id=' + clientId);
        } else if (this.clientId && this.clientId !== 'all') {
            params.push('client_id=' + this.clientId);
        }
        if (status) {
            params.push('status=' + status);
        }
        if (limit) {
            params.push('limit=' + limit);
        }
        if (offset) {
            params.push('offset=' + offset);
        }
        if (params.length > 0) {
            url = url + '?' + params.join('&');
        }

        return this.makeRequest<(User & { client_name: string })[]>('get', url);
    }

    public updateUserAdmin(userId: string, isAdmin: boolean) {
        const url = this.configService.restApiUrl + '/admin/users/update/admin/' + userId;
        const body = {
            is_admin: isAdmin
        };
        return this.makeRequest<string>('put', url, body);
    }

    public updateUser(userId: string, userContentModel: Contact) {
        const url = this.configService.restApiUrl + '/admin/users/update/' + userId;
        const body = userContentModel;
        return this.makeRequest<string>('put', url, body);
    }

    // HELPER FUNCTIONS

    private makeRequest<T>(method: 'get' | 'post' | 'put' | 'delete', url: string, body?: any, responseType?: any, params?: any) {
        return new Promise<T>((resolve, reject) => {
            const headers = new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.userService.getCurrentSession().session_id
                }
            );

            const options = {
                headers,
                responseType: null,
                params: null
            };

            if (responseType) {
                options.responseType = responseType;
            }

            if (params) {
                options.params = params;
            }

            const handleError = (error: any) => {
                if (error.error && (error.error === 'Invalid user' || error.error === 'Session expired' || error.error === 'Invalid session')) {
                    swal.fire({
                        title: 'Session Expired',
                        text: 'Your session has expired. Please sign in again.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            this.storageService.removeFromStorage(this.configService.userSessionKey);
                            this.userService.clearUserData();
                            this.router.navigate(['/login']);
                            this.store.dispatch([ResetClientsState, ResetAuditTemplatesState, ResetAssetTemplatesState, ResetReportsState, ResetUsersState, ResetClientSelectionState]);
                        }
                    });
                } else if (error.error && (error.error === 'No response')) {
                    swal.fire({
                        title: 'Error Connecting To Gateway',
                        text: 'Please contact administrator.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            reject(error);
                        }
                    });
                } else {
                    swal.fire({
                        title: 'Unexpected Error',
                        text: 'Please contact administrator.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            reject(error);
                        }
                    });
                }
            };

            if (method === 'get') {
                this.httpClient.get<T>(url, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'post') {
                this.httpClient.post<T>(url, body, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'put') {
                this.httpClient.put<T>(url, body, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'delete') {
                this.httpClient.delete<T>(url, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            }
        });
    }

    private get clientId(): string {
        return this.store.selectSnapshot(ClientSelectionState.getCurrentClient);
    }
}
